import React from 'react'
import alex from '../../../assets/images/story/gorgias/alex-plugaru.png'
import image1 from '../../../assets/images/story/gorgias/gorgias-1.png'
import image2 from '../../../assets/images/story/gorgias/gorgias-2.png'
import image3 from '../../../assets/images/story/gorgias/gorgias-3.png'
import image4 from '../../../assets/images/story/gorgias/gorgias-4.png'
import image5 from '../../../assets/images/story/gorgias/gorgias-5.png'
import image6 from '../../../assets/images/story/gorgias/gorgias-6.png'
import image7 from '../../../assets/images/story/gorgias/gorgias-7.png'
import { InlineQuote } from '../../blocks/InlineQuote'
import Content from '../Content'
import { ProductScreenshot } from '../ProductScreenshot'

const GorgiasBody = () => {
  return (
    <Content
      sidebar={{
        company: (
          <p>
            Gorgias is an AI-powered customer service platform specifically
            designed for ecommerce businesses. Today, Gorgias serves 15,000+
            brands around the world.
          </p>
        ),
        href: 'https://gorgias.com',
        location: (
          <p>
            Founded in Paris, France. Headquartered in San Francisco,
            California. Global operations.
          </p>
        ),
        headcount: <p>450+ employees, 100+ in engineering</p>,
        customers: <p>2021</p>,
      }}
      main={
        <>
          <p>
            Alex Plugaru is the co-founder and CTO of Gorgias, a customer
            service platform specialized in serving ecommerce companies. Alex
            leads Gorgias’ 100-person product & engineering organization whose
            main goal is to meet and exceed the expectations of their
            fast-growing customer base by continuously delivering exceptionally
            good software.
          </p>
          <p>
            While Gorgias’ engineering organization has been using Swarmia since
            2021, Alex recognizes that in many ways, their need for a software
            engineering intelligence platform like Swarmia has only increased
            over time.
          </p>
          <InlineQuote
            quote="What changed since 2021 is that the bar got a lot higher. A lot of companies are trying to make their operations more efficient, consolidating software, and trying to find the solution that is the most effective in increasing the productivity of their team."
            name="Alex Plugaru"
            title="Co-Founder & CTO"
            photo={alex}
          />
          <p>
            For Gorgias, the changing market conditions have led to two things:
            1) forcing the engineering leadership to improve the way they
            sequence and track strategically important work, and 2) moving
            upmarket to correspond to changes in demand.
          </p>
          <InlineQuote
            quote="I think it was Jason Lemkin who coined the term hyperfunctional SaaS, which pretty accurately describes the situation we’re in. It basically means you’ve got to do everything: you’ve got to do reliability, you’ve got to do enterprise, it’s got to be cheap, it’s got to be better in every way."
            name="Alex Plugaru"
            title="Co-Founder & CTO"
          />
          <p>
            With increasing pressure from the market, Alex considers it more
            important than ever to foster a culture of transparency and
            ownership. His goal is to give every team, manager, and director
            access to data that helps them make better decisions locally as well
            as tools that help them systematically eliminate the bottlenecks
            that slow them down.
          </p>
          <h2>
            Serving the whole engineering organization with relevant insights
            and powerful tools
          </h2>
          <p>
            When Alex first found out about Swarmia in 2021, he was skeptical
            whether it was the right time for the engineering organization to
            start looking into developer productivity metrics. However, he
            quickly understood that the real benefit in a tool like Swarmia
            aren’t the metrics, but the conversations teams start having both
            amongst themselves and with the wider organization as a result of
            the new kind of visibility.
          </p>
          <InlineQuote
            quote="Once I started looking into Swarmia, I quickly realized that not only does it help me ask the right questions about developer productivity and happiness but it also gives our engineers tools to speed up their workflows."
            name="Alex Plugaru"
            title="Co-Founder & CTO"
          />
          <p>
            At Gorgias, software engineers and their managers use Swarmia every
            day, while engineering directors, VPs, and the CTO, Alex, look at
            the data on a weekly and monthly cadence.
          </p>
          <h3>Speeding up pull request flow in teams</h3>
          <p>
            Before adopting Swarmia, Gorgias’ software engineering teams had
            already started having discussions about stale pull requests and the
            difficulty of getting their code reviewed as quickly as they would
            have wanted. By looking into the flow insights in Swarmia, the teams
            found three key insights about code reviews:
          </p>
          <ol>
            <li>
              In all teams, there were outlier pull requests that were not
              touched for multiple days or even weeks while open.
            </li>
            <ProductScreenshot src={image1} />
            <li>
              Some of the teams also noticed that their relatively long review
              times were mostly caused by cross-team reviews taking
              significantly longer than internal team reviews.
            </li>
            <ProductScreenshot src={image2} />
            <li>
              The teams also noticed that they lacked common guidelines for code
              review best practices.
            </li>
          </ol>
          <InlineQuote
            quote="We used Swarmia's flow insights to validate our hypotheses with data and drill down to what’s slowing down our flow of delivery. This helped us conclude what to improve and how."
            name="Alex Plugaru"
            title="Co-Founder & CTO"
          />
          <p>
            Gorgias’ engineers also immediately found Swarmia’s two-way
            integration between GitHub and Slack useful. After setting relevant
            working agreements in Swarmia to limit work in progress and set
            acceptable targets for code review time, the teams were able to get
            a daily digest to their team’s Slack channel to help them stay on
            top of ongoing work.
          </p>
          <ProductScreenshot src={image3} />
          <p>
            Many of the developers also adopted personal Slack notifications to
            help them stay on top of any ongoing discussions in the comments of
            their pull requests. Instead of having to jump between browser
            windows, they can quickly read, react to, and reply to GitHub
            comments without leaving Slack.
          </p>
          <ProductScreenshot src={image4} />
          <InlineQuote
            quote="The Slack notifications in particular are excellent — way better than the GitHub ones. I was very surprised at how fast our engineers actually adopted Swarmia in their daily routine, and I’m sure the notifications played their part in that."
            name="Alex Plugaru"
            title="Co-Founder & CTO"
          />
          <h3>
            Using DORA metrics to communicate with non-engineering leadership
          </h3>
          <p>
            Over time, DORA metrics have become an important tool for the
            Gorgias engineering organization. Alex uses them to inform the
            reliability roadmaps he’s building and ultimately, to measure the
            impact of organization-wide productivity initiatives.
          </p>
          <InlineQuote
            quote="DORA metrics are a good indicator of whether there’s something we should worry about. We can make hypotheses like ‘If we invest more in end-to-end testing, we should see our change failure rate drop by X percent.’ And that opens up interesting conversations."
            name="Alex Plugaru"
            title="Co-Founder & CTO"
          />
          <ProductScreenshot src={image5} />
          <p>
            Alex has also found DORA metrics helpful in communicating about the
            health and speed of the engineering organization to his
            non-technical counterparts.
          </p>
          <InlineQuote
            quote="Having easy access to industry standard metrics like DORA reduces the friction between us and other departments. Engineering is typically one of the most expensive resources, and DORA metrics help us prove that we are still shipping fast without introducing too many new bugs to the system. It helps us have informed discussions with other functions."
            name="Alex Plugaru"
            title="Co-Founder & CTO"
          />
          <h3>Complementing metrics with developer experience surveys</h3>
          <p>
            In addition to more traditional engineering metrics, Gorgias has
            also recently started experimenting with running developer
            experience surveys in Swarmia. By combining the two types of
            insights, they get a holistic understanding of developer
            productivity and developer experience.
          </p>
          <InlineQuote
            quote="What I like about running developer experience surveys in Swarmia is that it comes with a set of solid survey questions. After the survey has ended, we can see the qualitative and quantitative data in one place, which you don’t really get in any other tool."
            name="Alex Plugaru"
            title="Co-Founder & CTO"
          />
          <p>
            In fact, Alex says that if he was introduced to Swarmia today, he’d
            probably start by running a survey. The results are easy to
            understand, and unlike system metrics, the qualitative survey
            answers often contain practical ideas on how to improve the systems
            and processes that are getting in the way of great developer
            experience.
          </p>
          <h3>Creating software capitalization reports in minutes</h3>
          <p>
            Recently, Alex and his team have also spent a lot of time on
            creating software capitalization reports. Before Swarmia, this was a
            manual process that involved extracting issues from Linear, Gorgias’
            issue tracker, to spreadsheets and making an estimate of how much
            engineering effort each Linear issue required.
          </p>
          <p>
            In addition to being slow and painful to create, the manual reports
            were also difficult to consume as they consisted of hundreds of
            projects.
          </p>
          <InlineQuote
            quote="Manually mapping projects into initiatives used to take a lot of time. We collectively spent hundreds of hours in getting these reports to a shape where they were easy to consume, whereas now we can just set up investment categories in Swarmia and build our software capitalization reporting on top of that."
            name="Alex Plugaru"
            title="Co-Founder & CTO"
          />
          <ProductScreenshot src={image6} />
          <h2>
            Better visibility, faster cycle times, and saving hundreds of hours
            in software capitalization reporting
          </h2>
          <p>
            After just two months of using Swarmia, Gorgias already started
            seeing promising results in the teams’ average pull request cycle
            times.
          </p>
          <InlineQuote
            quote="With Swarmia we’re able to highlight problem areas, set target levels, and drive continuous improvement. In just two months, we decreased our teams' cycle times by 40%."
            name="Alex Plugaru"
            title="Co-Founder & CTO"
          />
          <ProductScreenshot src={image7} />
          <p>
            In addition to saving hundreds of hours of work with Swarmia’s
            software capitalization feature, Alex is also impressed with the
            high Swarmia adoption rate among the Gorgias engineers.
          </p>
          <InlineQuote
            quote="80-90% of our software engineers use Swarmia daily. They get better statistics, better notifications, and a better understanding of the things they can improve on a day-to-day basis."
            name="Alex Plugaru"
            title="Co-Founder & CTO"
          />
          <p>
            The individual contributors aren’t, however, the only active users
            of Swarmia at Gorgias. Today, Alex considers the platform as an
            integral part of the way the whole engineering organization operates
            and collaborates with the rest of the business.
          </p>
          <InlineQuote
            quote="Swarmia responds to the entire engineering organization’s needs. As a leader, I get a global, data-driven overview of what’s going on in my organization. It helps me drive informed discussions with not only the engineering managers and individual contributors but also other functions like finance and product."
            name="Alex Plugaru"
            title="Co-Founder & CTO"
          />
        </>
      }
    />
  )
}
export default GorgiasBody
