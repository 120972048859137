import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Header from '../Header'

const GorgiasHeader = ({ featured }: { featured?: boolean }) => {
  const data = useStaticQuery(query)
  const photo = data.hero.childImageSharp.gatsbyImageData
  return (
    <Header
      photo={photo}
      heading="Gorgias makes better decisions and saves hundreds of hours of manual work"
      company="gorgias"
      subheading="Gorgias has been using Swarmia for years to empower everyone in the engineering organization with the right data and continuously improve the speed at which they bring new features to the market.
"
      featured={featured}
    />
  )
}

export default GorgiasHeader

const query = graphql`
  {
    hero: file(relativePath: { eq: "images/story/gorgias/hero-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 480, height: 540, layout: FIXED)
      }
    }
  }
`
