import React from 'react'
import Results from '../Results'

const GorgiasResults = () => {
  return (
    <Results
      results={[
        { name: 'cycle time', value: 37, dir: 'down' },
        { name: 'in progress time', value: 39, dir: 'down' },
        { name: 'review time', value: 30, dir: 'down' },
      ]}
    />
  )
}
export default GorgiasResults
