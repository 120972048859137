import React from 'react'
import CTASection from '../../components/CTASection'
import Layout from '../../components/Layout'
import CaseStudyContainer from '../../components/story/CaseStudyContainer'
import GorgiasBody from '../../components/story/gorgias/Content'
import GorgiasHeader from '../../components/story/gorgias/Header'
import GorgiasResults from '../../components/story/gorgias/Results'
import MetaImage from '../../assets/images/story/gorgias/gorgias-cover.png'

const GorgiasPage = () => {
  return (
    <Layout
      title="Gorgias case study"
      description="How Gorgias makes better decisions and saves hundreds of hours of manual work"
      metaImage={MetaImage}
    >
      <CaseStudyContainer>
        <GorgiasHeader />
        <GorgiasResults />
        <GorgiasBody />
      </CaseStudyContainer>
      <CTASection>
        Swarmia is how the best software teams get better. Get started today.
      </CTASection>
    </Layout>
  )
}
export default GorgiasPage
